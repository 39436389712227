:root {
  --fuente-heading: "Poppins", sans-serif;
  --fuente-parrafos: "Poppins", sans-serif;

  --yellow: rgb(242, 161, 25);
  --yellow-hover: rgb(181, 122, 21);
  --blue: rgb(#151d2b);
  --white: #ffffff;
  --negro: #000000;
}

/* apply a natural box layout model to all elements, but allowing components to change */

html {
  box-sizing: border-box;
  scroll-snap-type: y mandatory;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: var(--fuente-parrafos);
  font-size: 1.6rem;
  line-height: 2;
}

.contenedor {
  max-width: 120rem;
  width: 90%;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
p,
div {
  font-family: var(--fuente-heading);
  line-height: 1.2;
}

h1 {
  font-size: 4.8rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 3.2rem;
}

h4 {
  font-size: 2.8rem;
}

img {
  max-width: 100%;
}

.form-container {
  margin-top: 3%;
}

.oxycopy-header {
  text-align: center;
}

.oxycopy-footer {
  padding-top: 2rem;
  text-align: center;
}

.oxycopy-stack {
  align-items: center;
  width: 12% !important;
}

.oxy-image {
  min-width: 10rem;
}

.ead-logo {
  min-width: 8rem;
  padding-right: 1rem;
}

/* Style submit button */
.btn,
.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  margin-top: 2rem !important;
  background-color: var(--yellow) !important;
  border-color: var(--yellow) !important;
}

.btn-primary:hover {
  margin-top: 2rem !important;
  background-color: var(--yellow-hover) !important;
  border-color: var(--yellow) !important;
}

.btn-primary:active {
  margin-top: 2rem !important;
  background-color: darkblue !important;
  border-color: var(--yellow) !important;
}

.submit-button {
}

/* Style the custom-modal */
.custom-modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* custom-modal Content */
.custom-modal-content {
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fefefe;
}

.custom-modal-text {
  margin-left: 14px;
  font-size: 24px;
}

/* Create the circle loader with pure CSS */
.loader {
  width: 50px;
  height: 50px;
  border: 8px solid var(--blanco);
  border-top: 8px solid var(--yellow);
  border-bottom: 8px solid var(--yellow);
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.modal-response-background {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-response-wrapper {
  width: 60vh;
  min-height: 40vh;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  /* grid-template-rows: 1fr 1fr 1fr; */
  justify-items: center;
  position: relative;
  z-index: 10;
  border-radius: 10px;
  padding: 2rem;
}

.modal-response-image {
  width: 30%;
  border-radius: 10px 0 0 10px;
}

.modal-response-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
}

.modal-response-close-button {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
}

.modal-response-share-buttons {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.modal-response-share-buttons > div {
  margin-right: 8px;
  cursor: pointer;
}

.modal-response-is-copied-message {
  color: green;
  font-weight: bold;
  margin-top: 1rem;
}

.captcha-container{
  position: relative;
  margin-top: 10px;
}

div.captcha{
  display: flex;
  justify-content: center;
  margin: 20px auto;
}

div.captcha:first-child:first-child{
  margin-left: auto;
  margin-right: auto;
  width: 100px !important;
}


